/* global geojsonData */
/* global crb_app */
import mapboxgl from 'mapbox-gl';

if ($("body").hasClass("tax-type") || $("body").hasClass("single-program")) {

    mapboxgl.accessToken = crb_app.mapbox_token;

    const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [8.16263, 49.17416],
        zoom: 3.90,
    });

    class ProgramsMap {
        initializeMap() {
            map.on('load', async () => {

               // add markers to map
              for (const feature of geojsonData.features) {
                // create a HTML element for each feature
                const el = document.createElement('div');
                el.className = 'marker';

                let city = '';
                if (feature.properties.city != '') {
                  city = `<p class="mapcard-city">
                            <span class="mapcard-city-icon"></span>
                            ${feature.properties.city}
                          </p>`;
                }

                // make a marker for each feature and add to the map
                new mapboxgl.Marker(el)
                  .setLngLat(feature.geometry.coordinates)
                  .setPopup(
                    new mapboxgl.Popup({ offset: 25 }) // add popups
                      .setHTML(
                        `<a href="${feature.properties.url}" class="card mapcard-program">
                            <div class="mapcard-img">
                              ${feature.properties.thumbnail}
                              <span class="mapcard-badge">${feature.properties.progress}</span>
                              <span class="mapcard-fakelink">
                                <span class="mapcard-fakelink-icon"></span>
                              </span>
                            </div>
                            <p class="mapcard-title">${feature.properties.name}</p>
                            ` + city + `
                        </a>`
                      )
                  )
                  .addTo(map);
              }
            });
        }

        async initialize() {
            this.initializeMap();
            // update the map with the last lots added
            this.updateMap();
        }

        // Updates datas used as a source by the map
        updateMap() {
            let source = map.getSource('programs');

            // in case the map isn't initialized for the moment
            if (source) {
                source.setData(geojsonData);
            }

            if (geojsonData) {
                // Calculer les bornes pour englober tous les points
                const coordinates = geojsonData.features.map(feature => feature.geometry.coordinates);
                const bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

                map.fitBounds(bounds, {
                    padding: 20,
                    maxZoom: 15,
                });
            }
        }
    }

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    (async function () {
        const program_map = new ProgramsMap();
        await program_map.initialize();
    }(jQuery));

}
